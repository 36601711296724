/* eslint-disable react/prop-types */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import USAFlag from '../../atoms/USAFlag';

const FooterBox = styled(Box)({
  display: 'flex',
  backgroundColor: '#2A2A2A',
  flexFlow: 'row',
  justifyContent: 'center',
  width: '100%',
  position: 'fixed',
  padding: '4px',
  bottom: 0,
  zIndex: 1000,
  alignItems: 'center',
  height: 36,
  fontSize: 12,
  color: '#949494',
  '& span': { fontSize: 'inherit', color: '#FDD015' },
  '& p': { fontSize: 'inherit' }
});

const FooterContainer = styled('div')({
  width: '100%'
});

const Footer = () => {
  return (
    <FooterContainer>
      <FooterBox>
        <Typography>
          &copy; {new Date().getFullYear()} <strong>Malware Monster</strong> &nbsp; <USAFlag/>
        </Typography>
      </FooterBox>
    </FooterContainer>
  );
};

export default Footer;
