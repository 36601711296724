import React from 'react';
import AssassinLogo from '../../../assets/images/AssassinLogoBlack.png';

const Logo = () => (
  <img
    style={{ 
        height: 200,
        verticalAlign: 'middle' 
    }}
    src={AssassinLogo}
    alt="Malware Assassins"
    title="Provided by Malware Assassins"
  />
);
export default Logo;