import React from 'react';
import USAFlag from '../../../assets/images/USAFlag.png';

const Flag = () => (
  <img
    style={{ 
        height: 20,
        verticalAlign: 'middle' 
    }}
    src={USAFlag}
    alt="Malware Monster"
    title="Proudly made in the USA."
  />
);
export default Flag;
