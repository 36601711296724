import * as React from 'react';
import { styled } from '@mui/material/styles';
import LoginPageForm from '../../organisms/LoginPageForm';
import Footer from '../../organisms/Footer';
import Header from '../../organisms/Header';
import InnerContainerPublic from '../../organisms/InnerContainerPublic';
import WrapperContainerPublic from '../../organisms/Header/WrapperContainerPublic';
import MalwareAssassinsLogo from '../../atoms/AssassinLogo';


const BroughtToYouBy = styled('div')({
  color: '#fff'
});


const LoginPage = () => {
  return (
    <WrapperContainerPublic component="div" maxWidth="false" disableGutters>
      <Header />
      <InnerContainerPublic>
        <LoginPageForm />
        <BroughtToYouBy>Brought to you by:</BroughtToYouBy>
        <a href="https://www.teamworxsecurity.com" target="_blank" rel="noreferrer"> 
          <MalwareAssassinsLogo/>
          </a>
        <br/><br/>
      </InnerContainerPublic>
      <Footer />
    </WrapperContainerPublic>
  );
};

export default LoginPage;
