import React, { useRef, useContext, useState, forwardRef } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import { styled } from '@mui/material/styles';
import { TextField, Button, Link, CircularProgress } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import MailOutline from '@mui/icons-material/MailOutline';
import LockOutlined from '@mui/icons-material/LockOutlined';
import Alert from '@mui/material/Alert';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Context as authContext } from '../../../context/auth';
import { Context as userContext } from '../../../context/user';
import { USER_LOGIN } from './mutations';

const ReCAPTCHABox = styled('div')({
  marginTop: '8px',
  marginBottom: '16px'
});

const StyledLink = styled(Link)({
  fontSize: '0.775rem'
});



const ResetLink = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <RouterLink ref={ref} to="/reset" {...props} />
));

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .max(120, 'Must be under 120 characters')
    .trim()
    .email('Enter a valid email')
    .required('Email is required'),
  pin_code: yup
    .string('Enter your PIN Code')
    .max(120, 'Must be under 120 characters')
    .trim()
    .required('PIN Code is required'),
  recaptcha: yup.string().required()
});

const LoginForm = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const recaptchaRef = useRef(null);
  const { setAuthToken } = useContext(authContext);
  const { setCurrentUser } = useContext(userContext);
  const navigate = useNavigate();

  const [loginUser] = useMutation(USER_LOGIN);

  const formik = useFormik({
    initialValues: {
      email: '',
      pin_code: '',
      recaptcha: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      await loginUser({
        variables: {
          email: values.email.toLowerCase(),
          pin_code: values.pin_code,
          recaptcha: values.recaptcha
        }
      })
        .then(({ data }) => {
          formik.setSubmitting(false);
          setAuthToken(Cookies.get('refresh_token'));
          setCurrentUser({
            ...data.malmon_user_login
          });
          setLoading(false);
          navigate('/form', { replace: true });
        })
        // err
        .catch((e) => {
          formik.setSubmitting(false);
          recaptchaRef.current.reset();
          setLoading(false);
          setDisableSubmit(true);
          if (e.graphQLErrors[0]) setError(e.graphQLErrors[0].message);
        });
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        required
        id="email"
        label="Email"
        name="email"
        value={formik.values.email}
        margin="normal"
        fullWidth
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutline
                sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                fontSize="small"
              />
            </InputAdornment>
          )
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ 'data-testid': 'login-email' }}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <TextField
        required
        id="pin_code"
        label="PIN Code"
        name="pin_code"
        type="password"
        autoComplete="false"
        value={formik.values.pin_code}
        margin="normal"
        fullWidth
        onChange={formik.handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlined
                sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                fontSize="small"
              />
            </InputAdornment>
          )
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ 'data-testid': 'login-pin' }}
        error={formik.touched.pin_code && Boolean(formik.errors.pin_code)}
        helperText={formik.touched.pin_code && formik.errors.pin_code}
      />
      <StyledLink
        component={ResetLink}
        color="primary"
        underline="hover"
        variant="subtitle2"
      >
        Reset PIN Code &#8594;
      </StyledLink>
      <ReCAPTCHABox>
        <ReCAPTCHA
          sitekey={`${process.env.REACT_APP_SITE_KEY_RECAPTCHA}`}
          name="recaptcha"
          onChange={(value) => {
            formik.setFieldValue('recaptcha', value);
            formik.setSubmitting(false);
            setDisableSubmit(false);
          }}
          theme="dark"
          ref={recaptchaRef}
          data-testid="mock-v2-captcha-login"
        />
      </ReCAPTCHABox>
      <Button
        fullWidth
        variant="contained"
        type="submit"
        disabled={disableSubmit}
      >
        {loading ? <CircularProgress color="inherit" size={20} /> : 'Sign In'}
      </Button>
      {error && (
        <Alert severity="error" variant="filled" sx={{ marginTop: '12px' }}>
          {error}
        </Alert>
      )}
    </form>
  );
};

export default LoginForm;
